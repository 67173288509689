<template>
  <div class="home">
    <div class="main" v-title data-title='数字睿信' />
    <div id="carouselExampleSlidesOnly" class="carousel slide banner" data-ride="carousel" data-pause='false' data-interval="5000">
      <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="@/assets/home/20170327031548632.png" class="d-block w-100" alt="">
        </div>
        <div class="carousel-item">
          <img src="@/assets/home/20170421041406682.png" class="d-block w-100" alt="">
        </div>
        <div class="carousel-item">
          <img src="@/assets/home/20170421041537499.png" class="d-block w-100" alt="">
        </div>
        <div class="carousel-item">
          <img src="@/assets/home/20170421041617963.png" class="d-block w-100" alt="">
        </div>
        <div class="carousel-item">
          <img src="@/assets/home/20170421041737417.png" class="d-block w-100" alt="">
        </div>
      </div>
    </div>

    <div class="store">
      <div>
        <div class="title">
          <h1>APP STORE</h1>
          <h3>应用商店</h3>
          <span>超过180多种应用产品，1200多个独立功能模块。拥有最广泛，最权威的行业应用分类方案。</span>
        </div>
        <div class="centent">
          <div class="AboutBoc">
            <img src="@/assets/home/store1.jpg" alt="">
            <div class="boc">About Boc</div>
            <span>手机软件，就是安装在手机上的软件，完善原 始系统的不足与个性化。随着科技的发展，现在手机 的功能也越来越多，越来越强大。不是像过去的那么 简单死板，目前发展到了可以和电脑相媲美。</span>
          </div>
          <div class="AboutBoc">
            <img src="@/assets/home/store2.jpg" alt="">
            <div class="app">We App</div>
            <span>手机软件，就是安装在手机上的软件，完善原 始系统的不足与个性化。随着科技的发展，现在手机 的功能也越来越多，越来越强大。不是像过去的那么 简单死板，目前发展到了可以和电脑相媲美。</span>
          </div>
          <div class="AboutBoc">
            <img src="@/assets/home/store3.jpg" alt="">
            <div class="Honor_title">Honor</div>
            <span>手机软件，就是安装在手机上的软件，完善原 始系统的不足与个性化。随着科技的发展，现在手机 的功能也越来越多，越来越强大。</span>
          </div>
        </div>
      </div>
      <div>
        <div class="title">
          <h1>SUCCESSFUL CASE</h1>
          <h3>成功案例</h3>
        </div>
        <div class="case_img">
          <img class="case_img1" src="@/assets/home/20170317111049735.jpg" alt="">
          <img class="case_img2" src="@/assets/home/20170317102808776.png" alt="">
        </div>

      </div>
      <div>
        <div class="title">
          <h1>新闻中心</h1>
          <h3>NEWS TRENDS</h3>
        </div>
        <div class="title_img">
          <img  src="@/assets/home/036.jpg" alt="">
        </div>
        <div class="news">
          <h1>行业资讯</h1>
          <div class="app_new">
            <span class="text">App 没能杀死浏览器 浏览器反而变成了 App？</span>
            <span class="time">10-15</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
 <script>
import $ from 'jquery'
import '@/assets/bootstrap/css/bootstrap.min.css'
import '@/assets/bootstrap/js/bootstrap.min.js'
// $(document).ready(function(){
//   $(document).scroll(function(){
//     var res = $(this).scrollTop();
//     console.log(res)
//   })
// })
export default {
  name: 'Home',
  components: {
  }
}
</script>
<style scoped>

</style>
<style lang="less" scoped>
.banner{
  width: 100%;
  img{
    width: 100%;
  }
}
.store{
  width: 8.9552rem;
  margin: 0 auto;
  .title{
    font-size: 0.1194rem;
    margin-top: 1.4925rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      color: #287fdc;
      font-size: 0.2612rem;
      display: block;
      width: 100%;
      text-align: center;
      padding: 0.0746rem 0;
    }
    h3{
      color: #4d4d4d;
      font-size:0.1791rem;
      font-weight: normal;
      display: block;
      width: 100%;
      text-align: center;
      padding: 0.0746rem 0;
    }
    span{
      color: #818181;
    }
  }
  .centent{
    font-size: 0.1194rem;
    display: flex;
    padding: 0.1493rem 0;
    text-align: center;
    .AboutBoc{
      width: 2.6866rem;
      padding: 0 0.1493rem;
      img{
        padding: 0.1493rem 0;
        width: 1.194rem;
      }
      .boc{
        font-size: 0.1194rem;
      }
      span{
        margin-top: 0.0448rem;
        display: block;
        color: #333;
      }
    }
  }
  .case_img{
    display: flex;
    flex-direction: column;
    align-items: center;
    .case_img1{
      width: 3.3582rem;
    }
    .case_img2{
      width: 6.4925rem;
    }
  }
  .news{
    width: 2.6866rem;
    margin-bottom: 0.7463rem;
    h1{
      font-size: 0.1194rem;
      color: #333;
      margin: 0.1866rem 0;
    }
    .app_new{
      width: 2.6866rem;
      display: flex;
      justify-content: space-between;
      font-size: 0.1194rem;
      .text{
        display: block;
        width: 1.903rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space:nowrap;
      }
    }
  }
}
// 飞行随笔
.title_img{
  width: 2.6866rem;
  height: 1.0075rem;
  img{
    width: 100%;
  }

}

</style>
