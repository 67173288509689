<template>
  <div class='foot'>
      <div class="foot2">
          <div class="nav">
            <ul>
              <li class="top">快速导航</li>
              <li @click="home">首页</li>
              <li @click="ipone">移动平台</li>
              <li @click="product">产品中心</li>
              <li @click="Case">成功案例</li>
              <li @click="plan">解决方案</li>
              <li @click="news">新闻动态</li>
              <li @click="about">关于我们</li>
            </ul>
          </div>
          <div class="nav">
            <ul>
              <li class="top">北京数字睿信移动技术有限公司</li>
              <li>总部：北京市海淀区香山路88号2层</li>
              <li>广州分公司：广州市天河区天河北路906号A座22楼</li>
              <li>总部电话：010-88892031</li>
              <li>广州电话：020-38351618</li>
              <li>邮箱：service@szrxin.com</li>
              <li >ICP 证：<a style=" text-decoration: none;color: #FFFFFF;" href="https://beian.miit.gov.cn">京ICP备17070665号</a></li>
            </ul>
          </div>
      </div>

      <div class="foot1 clearfix">
          <div class="nav1">
            <h3>快速导航</h3>
            <div class="nav_list">
              <span  @click="home">首页</span>
              <span @click="ipone">移动平台</span>
              <span @click="product">产品中心</span>
              <span @click="Case">成功案例</span>
              <span @click="plan">解决方案</span>
              <span @click="news">新闻动态</span>
              <span @click="about">关于我们</span>
            </div>
          </div>
          <div class="nav2">
            <ul>
              <li class="top2">北京数字睿信移动技术有限公司</li>
              <li>总部：北京市海淀区香山路88号2层</li>
              <li>广州分公司：广州市天河区天河北路906号A座22楼</li>
              <li>总部电话：010-88892031</li>
              <li>广州电话：020-38351618</li>
              <li>邮箱：service@szrxin.com</li>
               <li >ICP 证：<a style=" text-decoration: none;color: #FFFFFF;" href="https://beian.miit.gov.cn">京ICP备17070665号</a></li>
            </ul>
          </div>
      </div>
  </div>
</template>

<script>
import '@/assets/title.css'
export default {
  name: 'vue',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    home(){
      this.$router.push({
        path:'/'
      })
    },
    ipone(){
      this.$router.push({
        path:'/ipone'
      })
    },
    product(){
      this.$router.push({
        path:'/product'
      })
    },
    Case(){
      this.$router.push({
        path:'/case'
      })
    },
    plan(){
      this.$router.push({
        path:'/plan'
      })
    },
    news(){
      this.$router.push({
        path:'/news'
      })
    },
    about(){
      this.$router.push({
        path:'/about'
      })
    }
  },
};
</script>

<style scoped lang='less'>
.foot2{
  background-image: url('../assets/home/0371.jpg');
  width: 100%;
  display: flex;
  color: white;
  // height: 3.7313rem;
  .nav{
    width: 3.7313rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 0.8955rem;
    .top{font-size: 0.1493rem;}
    ul{li{height: 0.2985rem;font-size: 0.1045rem;}}
  }
}

.foot1{
  background-image: url('../assets/home/0371.jpg');
  color: white;
  display: none;
  width: 100%;
  .nav1{
    // background-color: red;
    padding: 0.4478rem 0.0746rem;
    font-size: 0.1194rem;
    // width: 100%;
    text-align: center;
    border-bottom: 0.0075rem solid #cccccc;
    h3{
      margin-bottom: 0.1493rem;
      color: white;
      font-size: 0.2239rem;
    }
    .nav_list{
      // width: 100%;
      display: flex;
      justify-content: center;
      color: #ccc;
      span{
        margin: 0 0.1194rem;
      }
    }
  }
  .nav2{
    margin-top: 0.1493rem;
    padding-left: 0.1493rem;
    font-size: 0.1194rem;
    color: #cccccc;
  }
}
</style>
