import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
    {
      path: '/ipone',
      name: 'ipone',
      component: resolve => require(['@/views/ipone'],resolve)
    },
    {
      path: '/product',
      name: 'product',
      component: resolve => require(['@/views/product'],resolve)
    },
    {
      path: '/case',
      name: 'case',
      component: resolve => require(['@/views/case'],resolve)
    },
    {
      path: '/plan',
      name: 'plan',
      component: resolve => require(['@/views/plan'],resolve)
    },
    {
      path: '/news',
      name: 'news',
      component: resolve => require(['@/views/news'],resolve)
    },
    {
      path: '/about',
      name: 'about',
      component: resolve => require(['@/views/about'],resolve)
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
