import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/index.css'
import '@/assets/media.js'


Vue.config.productionTip = false

Vue.directive('title',{
  inserted:function(el,binding){
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
