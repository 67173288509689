<template>
  <div class='title1' @touchmove.prevent @mousewheel.prevent>
      <div class="title">
        <div class="logo">
          <img src="@/assets/home/logo.png" alt="">
        </div>
        <div class="the_tit">
          <ul>
            <li @click="home">首页</li>
            <li @click="ipone">移动平台</li>
            <li @click="product">产品中心</li>
            <li @click="Case">成功案例</li>
            <li @click="plan">解决方案</li>
            <li @click="news">新闻动态</li>
            <li @click="about">关于我们</li>
          </ul>
        </div>

        <div class="the_title"  @click="navList">
          <img src="@/assets/home/btn_05.png" alt="">
        </div>
    </div>
    <div class="the_list" v-if="!list" @click="navList">
            <div class="poput">
              <div class="popup_item" @click="home">首页</div>
              <div class="popup_item" @click="ipone">移动平台</div>
              <div class="popup_item" @click="product">产品中心</div>
              <div class="popup_item" @click="Case">成功案例</div>
              <div class="popup_item" @click="plan">解决方案</div>
              <div class="popup_item" @click="news">新闻动态</div>
              <div class="popup_item" @click="about">关于我们</div>
            </div>
        </div>
  </div>
</template>

<script>
import '@/assets/title.css'
export default {
  name: 'vue',
  props: {},
  data() {
    return {
      list:true,
    };
  },
  computed: {},
  created() {},
  methods: {
    navList(){
      if(this.list != true){
        this.list = true
      }else{
        this.list = false
      }
    },
    home(){
      this.$router.push({
        path:'/'
      })
    },
    ipone(){
      this.$router.push({
        path:'/ipone'
      })
    },
    product(){
      this.$router.push({
        path:'/product'
      })
    },
    Case(){
      this.$router.push({
        path:'/case'
      })
    },
    plan(){
      this.$router.push({
        path:'/plan'
      })
    },
    news(){
      this.$router.push({
        path:'/news'
      })
    },
    about(){
      this.$router.push({
        path:'/about'
      })
    }
  },
};
</script>

<style scoped lang='less'>
.title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20.0062px;
  .logo{
    width: 220.0012px;
  }
  .the_tit{
    flex: 1;
    ul{
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      li{list-style: none;height: 79.998px;line-height: 79.998px;font-size: 14.003px;padding-right: 20.0062px;}
      }
  }
}

.the_title{
  display: none;
  margin: 20.0062px;
  img{
    width: 25px;
    height: 25px;
  }
}
.navList{
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(136, 131, 131);
  z-index: 9999;
}

.poput{
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(84,127,207,0.9);
  z-index: 9998;
  .popup_item{
    width: 100%;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    color: white;
    text-align: left;
    border-bottom: 0.0075rem solid #90abff;
  }
}

</style>
