<template>
  <div id="app">
   <tit class="tit" />
    <router-view class="route"/>
    <foot class="foot" />
  </div>
</template>
<script>
import tit from '@/components/title.vue'
import foot from '@/components/foot.vue'
export default {
  name: 'App',
  components:{
    tit,
    foot
  }
}
</script>

<style lang="less" scoped>
#app{
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  .tit{
    width: 100%;
  }
  .route{
    width: 100%;
  }

  .foot{
    align-self: flex-end;
    width: 100%;
  }
}
</style>